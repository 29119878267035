<template>
  <div class="procureSendDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.procure.id ? '编辑' : '新增' }}采购明细（发车）</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="info" @click="data.procure = {}">清空</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="发货日期">
            <el-date-picker style="width: 100%;" v-model="data.procure.sendDate" type="date" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
        </el-col>
        <el-col :span="12">
          <el-form-item label="供应商">
            <el-select v-model="data.procure.supplier" filterable>
              <el-option v-for='supplierItem in show.supplierList' :key="supplierItem.id" :label="supplierItem.value" :value="supplierItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓储">
            <el-select v-model="data.procure.storage" filterable>
              <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运输公司">
            <el-select v-model="data.procure.transportCompany" filterable>
              <el-option v-for='transportCompanyItem in show.transportCompanyList' :key="transportCompanyItem.id" :label="transportCompanyItem.value" :value="transportCompanyItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
        </el-col>
        <el-col :span="12">
          <el-form-item label="煤矿品名">
            <el-select v-model="data.procure.product" filterable>
              <el-option v-for='productItem in show.productList' :key="productItem.id" :label="productItem.value" :value="productItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="规格">
            <el-select v-model="data.procure.productSpecs" filterable>
              <el-option v-for='productSpecsItem in show.productSpecsList' :key="productSpecsItem.id" :label="productSpecsItem.value" :value="productSpecsItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车号">
            <!-- <el-input v-model="data.procure.truckNumber" :max="100" /> -->
            <el-autocomplete style="width: 100%;" v-model="data.procure.truckNumber" :fetch-suggestions="getTruckNumbers" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
        </el-col>
        <el-col :span="12">
          <el-form-item label="发货数量">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.sendQuantity" @change="computeProductAmount()" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="煤矿单价">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.productPrice" @change="computeProductAmount()" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运输单价">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.transportPrice" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="煤矿总价">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procure.productAmount" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注">
            <el-input v-model="data.procure.note" :max="100" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const show = reactive({
  supplierList: [],
  storageList: [],
  productList: [],
  productSpecsList: [],
  transportCompanyList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "procureSendDetail")
const data = reactive({
  procure: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {}
})

api.get('/backend/dict/getByCode', { params: { code: 'supplier' } }).then(res => {
  show.supplierList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'product' } }).then(res => {
  show.productList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'productSpecs' } }).then(res => {
  show.productSpecsList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'transportCompany' } }).then(res => {
  show.transportCompanyList = res.dictList
})

const save = () => {
  const param = { id: data.procure.id }
  param.sendDate = util.parseTime(data.procure.sendDate, '{y}-{m}-{d}')
  param.supplier = data.procure.supplier
  param.storage = data.procure.storage
  param.transportCompany = data.procure.transportCompany
  param.product = data.procure.product
  param.productSpecs = data.procure.productSpecs
  param.truckNumber = data.procure.truckNumber
  param.sendQuantity = data.procure.sendQuantity
  param.productPrice = data.procure.productPrice
  param.transportPrice = data.procure.transportPrice
  param.productAmount = data.procure.productAmount
  param.note = data.procure.note
  api.post('/backend/procure/send', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const getTruckNumbers = (queryString, callback) => {
  api.get('/backend/procure/getTruckNumbers', { params: { truckNumberLike: queryString } }).then(res => {
    callback(res.list.map(item => { return { value: item } }))
  })
}

const computeProductAmount = () => {
  if (data.procure.sendQuantity && data.procure.productPrice) {
    let productAmount = math.multiply(data.procure.sendQuantity, data.procure.productPrice)
    if (data.procure.productAmount != productAmount) {
      data.procure.productAmount = productAmount
      ElMessage.success('货款已自动更新')
    }
  }
}

// 关闭页面生命周期
onUnmounted(() => {
  if (!data.procure.id) {
    if (JSON.stringify(data.procure) == '{}') {
      cache.setObject(cache.keys.pageCache + "procureDetail", null)
    } else {
      cache.setObject(cache.keys.pageCache + "procureDetail", data.procure)
    }
  }
})
if (!data.procure.id) {
  const procure = cache.getObject(cache.keys.pageCache + "procureDetail")
  if (procure) {
    data.procure = procure
    ElMessage.success('已快捷填写上次填写的信息')
  }
}
</script>

<style lang="less">
.procureSendDetail {
  margin: auto;
  max-width: 900px;
}
</style>